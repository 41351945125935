import { createSlice } from '@reduxjs/toolkit';
import { updateStatus } from './homeAPI';

const initialState = {
    data: {},
    status: 'idle',
    name: "User"
};

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        updateName: (state, action) => {
            const homeData = state;
            homeData.name = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateStatus.fulfilled, (state, action) => {
                const homeData = state;
                homeData.status = action.payload
            })
        builder
            .addCase(updateStatus.pending, (state) => {
                const homeData = state;
                homeData.status = "Pending"
            })
    }
});

export const { updateName } = homeSlice.actions;

export default homeSlice.reducer;
