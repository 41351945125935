import { generateQuery } from '../helpers/utis';

export const AuthApi = {
  loginApi: {
    url: '/user/login',
    method: 'post',
    get api() {
      return this.url
    },
  },  
 
  verifyApi: {
    url: '/user/resetPasswordToken',
    baseURL: 'normal',
    method: 'get',
    query: {
      token: ''
    },
    get api() {
      return `${this.url}${generateQuery(this.query)}`;
    }
  },
  changePasswordApi: {
    api: '/user/resetPassword',
    baseURL: 'normal',
    method: 'post'
  }
};
// USER
export const GetAdminDetailsById = {
  adminDetailsByIdApi: {
    url: '/user/detail/',
    method: 'get',
    get api() {
      return this.url + this.body.slug
    },
  }
};

export const GetAdminList = {
  adminListApi: {
    url: '/user/list',
    method: 'get',
    get api() {
      return this.url + this.body.slug
    }
  }
};
export const DeleteAdminByID = {
  deleteAdminApi: {
    url: '/user/delete',
    method: 'delete',
    get api() {
      return this.url + this.body.slug
    },
  }
};
export const CreateUser = {
  userRegisterApi: {
    url: '/user/create',
    method: 'post',
    get api() {
      return this.url;
    },
  }
};
export const UpdateUser = {
  userUpdateApi: {
    url: '/user/edit?id=',
    method: 'post',
    get api(){
      return this.url + this.body.id
    }
  }
};

// customer

export const GetCustomerDetailsById = {
  customerDetailsByIdApi: {
    url: '/customer/details/',
    method: 'get',
    get api() {
      return this.url + this.body.slug
    },
  }
};

export const GetCustomerList = {
  customerListApi: {
    url: '/customer/list',
    method: 'get',
    get api() {
      return this.url + this.body.slug
    }
  }
};

export const DeleteCustomerByID = {
  deleteCustomerApi: {
    url: '/customer/delete',
    method: 'delete',
    get api() {
      return this.url + this.body.slug
    },
  }
};

// GroupFlow


export const GetGroupDetailsById = {
  groupDetailsByIdApi: {
    url: '/customer/list/',
    method: 'get',
    get api() {
      return this.url + this.body.slug
    },
  }
};

export const GetGroupList = {
  groupListApi: {
    url: '/group/list',
    method: 'get',
    get api() {
      return this.url + this.body.slug
    }
  }
};


export const DeleteGroupByID = {
  deleteAdminApi: {
    url: '/user/delete',
    method: 'delete',
    get api() {
      return this.url + this.body.slug
    },
  }
};

// PRODUCT
export const GetProdcutDetailsById = {
productDetailsByIdApi: {
    url: '/product/details',
    method: 'get',
    get api() {
      return this.url + this.body.slug
    },
  }
};

export const GetProductList = {
  productListApi: {
    url: '/product/lists',
    method: 'get',
    get api() {
      return this.url + this.body.slug
    }
  }
};
export const DeleteProductById = {
  deleteProductApi: {
    url: '/product/delete',
    method: 'delete',
    get api() {
      return this.url + this.body.slug
    },
  }
};
export const CreateProduct = {
  productRegisterApi: {
    url: '/product/create',
    method: 'post',
    get api() {
      return this.url;
    },
  }
};
export const UpdateProduct = {
  productUpdateApi: {
    url: '/product/edit?productId=',
    method: 'post',
    get api(){
      return this.url + this.body.id
    }
  }
};