import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import { store } from 'helpers';
import { ToastBar, Toaster } from 'react-hot-toast';

import 'assets/scss/app.scss';
import { router } from 'routes';
import { ErrorBoundary } from 'helpers/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<span>Loading...</span>}>
        <ErrorBoundary>
          <RouterProvider router={router} />
        </ErrorBoundary>
      </Suspense>
      <Toaster
        position="bottom-right"
        gutter={8}
        reverseOrder
        containerClassName="custom-toast"
        containerStyle={{ right: '40px', bottom: '50px' }}
      >
        {(t) => (
          <ToastBar toast={t} style={{ padding: 0, overflow: 'hidden' }}>
            {({ icon, message }) => {
              return (
                <div
                  className={`custom-toast-${t.type} d-flex align-items-center custom-toast-item`}
                >
                  {icon}
                  {message}
                </div>
              );
            }}
          </ToastBar>
        )}
      </Toaster>
    </Provider>
  </React.StrictMode>
);
