import React, { useState } from 'react';
import { FaUserCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import menuItems from '../../helpers/menuItems';
import { MenuItem } from './MenuItem';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate(); // Get the history object from react-router-dom

  const handleItemClick = (route) => {
    navigate(route); // Navigate to the specified route
    closeSidebar();
  };

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    console.log('Closing sidebar');
    setIsSidebarOpen(false);
  };
  
 
  return (
    <>
      

      <div className={`deznav ${isSidebarOpen ? 'show' : ''}`}>
        <div className='deznav-scroll'>
          <ul className='metismenu' id='menu'>
            {menuItems.map((data) => (
              <MenuItem {...data} key={data.title} onItemClick={() => handleItemClick(data.route)} />
            ))}
          </ul>
        </div>      
      </div>
      
    </>
  );
};

export default Sidebar;
