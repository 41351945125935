/* eslint-disable import/no-cycle */
import { AuthApi,CreateUser,GetAdminList,GetAdminDetailsById,DeleteAdminByID,DeleteCustomerByID,UpdateUser,GetCustomerList,GetCustomerDetailsById,GetGroupDetailsById,GetGroupList,GetProdcutDetailsById,GetProductList,DeleteProductById,CreateProduct,UpdateProduct } from '../../../service/apiVariables';
import { setAuthToken } from '../../../service/utilities';

const { createAsyncThunk } = require('@reduxjs/toolkit');

const namespace = 'home';


export const updateStatus = createAsyncThunk(
  `${namespace}/userlogin`,
  async (payload, { extra: { api, Toast } }) => {

    const { loginApi } = AuthApi
    loginApi.body = payload
    try {
      const { data, message } = await api({
        ...loginApi,
      })
      if (data?.token) {
        Toast({
          type: 'success',
          message
        })
        localStorage.setItem('userName', data?.userName)
        localStorage.setItem('id', data?.id)
        setAuthToken(data?.token)

      }
      return { data, message }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

// admin
export const UserAdminList = createAsyncThunk(
  `${namespace}/adminlist`,
  async (payload, { extra: { api, Toast } }) => {

    const { adminListApi } = GetAdminList
    adminListApi.body = payload
    try {
      const { data } = await api({
        ...adminListApi
      })
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

export const UserAdminDetails = createAsyncThunk(
  `${namespace}/admin-details`,
  async (payload, { extra: { api,Toast } }) => {

    const { adminDetailsByIdApi } = GetAdminDetailsById
    adminDetailsByIdApi.body = payload
    try {
      const { data } = await api({
        ...adminDetailsByIdApi
      })
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

export const userRegistration = createAsyncThunk(
  `${namespace}/userregister`,
  async (payload, { extra: { api, Toast } }) => {

    const { userRegisterApi } = CreateUser
    userRegisterApi.body = payload
    try {
      const { data, message, status } = await api({
        ...userRegisterApi
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })
      }
      return { data, message, status }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
export const userUpdation = createAsyncThunk(
  `${namespace}/edit-user`,
  async (payload, { extra: { api, Toast } }) => {

    const { userUpdateApi } = UpdateUser
    userUpdateApi.body = payload
    try {
      const { data, message } = await api({
        ...userUpdateApi
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })
      }
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
export const AdminDelete = createAsyncThunk(
  `${namespace}/deleteAdmin`,
  async (payload, { extra: { api, Toast } }) => {
    const { deleteAdminApi } = DeleteAdminByID
    deleteAdminApi.body = payload
    try {
      const { data, message } = await api({
        ...deleteAdminApi
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })
      }
      return { data }
    } catch (error) {     
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

// customer
export const CustomerList = createAsyncThunk(
  `${namespace}/customerlist`,
  async (payload, { extra: { api, Toast } }) => {

    const { customerListApi } = GetCustomerList
    customerListApi.body = payload
    try {
      const { data } = await api({
        ...customerListApi
      })
      return { data }
    } catch (error) {   
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

export const SingleCustomerDetails = createAsyncThunk(
  `${namespace}/singlecustomer`,
  async (payload, { extra: { api,Toast } }) => {

    const { customerDetailsByIdApi } = GetCustomerDetailsById
    customerDetailsByIdApi.body = payload
    try {
      const { data } = await api({
        ...customerDetailsByIdApi
      })
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
export const DeleteCustomerById = createAsyncThunk(
  `${namespace}/deletecustomer`,
  async (payload, { extra: { api, Toast } }) => {

    const { deleteCustomerApi } = DeleteCustomerByID
    deleteCustomerApi.body = payload
    try {
      const { data } = await api({
        ...deleteCustomerApi
      })
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
// GroupFlow

export const GroupList = createAsyncThunk(
  `${namespace}/groupflow`,
  async (payload, { extra: { api, Toast } }) => {

    const { groupListApi } = GetGroupList
    groupListApi.body = payload
    try {
      const { data } = await api({
        ...groupListApi
      })
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

export const GetGroupById = createAsyncThunk(
  `${namespace}/singlegroup`,
  async (payload, { extra: { api,Toast } }) => {

    const { groupDetailsByIdApi } = GetGroupDetailsById
    groupDetailsByIdApi.body = payload
    try {
      const { data } = await api({
        ...groupDetailsByIdApi
      })
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
export const DeleteGroupById = createAsyncThunk(
  `${namespace}/deletegroup`,
  async (payload, { extra: { api, Toast } }) => {

    const { customerListApi } = GetCustomerList
    customerListApi.body = payload
    try {
      const { data } = await api({
        ...customerListApi
      })
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

// product
export const productList = createAsyncThunk(
  `${namespace}/productlist`,
  async (payload, { extra: { api, Toast } }) => {

    const { productListApi } = GetProductList
    productListApi.body = payload
    try {
      const { data } = await api({
        ...productListApi
      })
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

export const productDetails = createAsyncThunk(
  `${namespace}/product-details`,
  async (payload, { extra: { api,Toast } }) => {

    const { productDetailsByIdApi } = GetProdcutDetailsById
    productDetailsByIdApi.body = payload
    try {
      const { data } = await api({
        ...productDetailsByIdApi
      })
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

export const productRegistration = createAsyncThunk(
  `${namespace}/productRegisterApi`,
  async (payload, { extra: { api, Toast } }) => {

    const { productRegisterApi } = CreateProduct
    productRegisterApi.body = payload
    try {
      const { data, message, status } = await api({
        ...productRegisterApi
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })
      }
      return { data, message, status }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
export const productUpdation = createAsyncThunk(
  `${namespace}/edit-product`,
  async (payload, { extra: { api, Toast } }) => {

    const { productUpdateApi } =  UpdateProduct
    productUpdateApi.body = payload
    try {
      const { data, message } = await api({
        ...productUpdateApi
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })
      }
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
export const productDelete = createAsyncThunk(
  `${namespace}/deleteProduct`,
  async (payload, { extra: { api, Toast } }) => {
    const { deleteProductApi } = DeleteProductById
    deleteProductApi.body = payload
    try {
      const { data, message } = await api({
        ...deleteProductApi
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })
      }
      return { data }
    } catch (error) {
      Toast({ type: 'error', message: 'Unable to process the request. Please try again later!' })
    }
    return payload;
  }
)